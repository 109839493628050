var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel"},[_c('phone-ctn',[_c('div',{staticClass:"page"},[(_vm.initIframe)?_c('iframe',{ref:"iframe",staticClass:"page-iframe",style:({
        height: _vm.iframeHeight + 'px',
        pointerEvents: _vm.control.dragstatus ? 'none' : 'auto',
      }),attrs:{"frameborder":"no","src":_vm.iframeUrl},on:{"load":_vm.init}}):_vm._e(),_c('div',{staticClass:"page-layer",style:({
        height: _vm.iframeHeight + 'px',
        zIndex: _vm.control.dragstatus ? '20' : '1',
      }),attrs:{"type":"page"},on:{"dragover":_vm.layerMove}},_vm._l((_vm.widgetInfoList),function(item){return _c('control-widget-shape',{key:item.id,attrs:{"data":item},on:{"changeCurrWidget":_vm.changeCurrWidget}},[_c('div',{ref:"layerWidget",refInFor:true,staticClass:"page-layer-widget",style:({ height: item.height + 'px' }),attrs:{"type":"widget","id":item.id}})])}),1)]),_c('el-tooltip',{attrs:{"effect":"light","content":"页面设置","placement":"bottom"}},[_c('div',{staticClass:"panel-set flex-center",on:{"click":function($event){_vm.control.curWidget = undefined}}},[_c('i',{staticClass:"el-icon-setting"})])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }