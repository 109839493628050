<!--
 * @Description: 物料列表 
 * @Autor: WangYuan
 * @Date: 2021-05-19 10:41:34
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-12 14:41:25
-->

<template>
  <div class="wrap">
    <div class="w-100 h-100 p16 bg-white" style="overflow: auto;">

      <ul>
        <li v-for="(item, index) in project.groupList" :key="index">

          <div class="toggle" @click="openClose(item, index)" style="margin:15px 0px;">
            <i v-if="item.componentList && item.componentList.length"
              :class="item.expanded ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
              style="margin-right: 5px;font-size: 16px;color:#101010;font-weight: bold"></i>
            <div style="padding:10px 0px;font-size:16px;color:#101010;font-weight: bold">{{ item.name }}</div>
          </div>

          <div class="flex flex-wrap" v-show="item.expanded">
            <div v-for="(items, indexs) in item.componentList" :key="indexs" @dragstart="handleDragStart"
              @dragend="dragEnd" :data-component="items.component.component" :data-disabled="items.component.disabled"
              draggable v-if="items.component">
              <el-tooltip placement="top" effect="light" content="组件数已达到上限" :disabled="!items.component.disabled">
                <div class="item" style="border:none;" :class="{ 'item-disabled': items.component.disabled }"
                  @mouseover="bindMouseover(index, indexs)" @mouseout="bindMouseout(index, indexs)">
                  <i class="icon" :class="items.component.icon" style="font-size:28px"></i>

                  <div class="lh-20" style="padding:10px 0px;">{{ items.component.name }}</div>
                  <div class="lh-20" v-if="items.replicaLimit && items.component.isHovered">
                    {{ items.component.used }} / {{ items.replicaLimit }}
                  </div>
                  <div class="lh-20" v-else style="line-height:20px;color:#fff">
                    11111
                  </div>

                  <!-- <div slot="content"> {{items.component.disabled?'组件数已达到上限':'已使用/可使用('+items.component.used+'/'+items.replicaLimit+')'}}</div> -->
                  <!-- v-show="items.replicaLimit && items.component.isHovered" -->

                </div>
              </el-tooltip>
            </div>
          </div>

        </li>
      </ul>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  inject: ["control"],

  data() {
    return {
      list: this.$initializing,
      groupList: []
    }
  },

  watch: {
    "control.curPage.componentEntityList": {
      handler(newValue) {
        console.log("--左侧组件列表---->>>>", newValue);
        for (let i in this.project.groupList) {

          for (let j in this.project.groupList[i].componentList) {

            let info = this.project.groupList[i].componentList[j].component;

            if (info) {
              let arr = [];
              if (newValue) {
                arr = newValue.filter(a => a.component == info.component);
              }

              if (this.project.groupList[i].componentList[j].replicaLimit) {
                this.project.groupList[i].componentList[j].component.used = arr.length;
                this.project.groupList[i].componentList[j].component.disabled = arr.length >= this.project.groupList[i].componentList[j].replicaLimit;

              } else {
                this.project.groupList[i].componentList[j].component.disabled = false;
              }

              this.project.groupList[i].componentList[j].component.isHovered = false;
            }
          }

        }
        this.$forceUpdate();

      },
      deep: true,
      immediate: true,
    }
  },
  computed: {
    ...mapGetters(["project"])
  },
  methods: {
    // 鼠标移入
    bindMouseover(key, key1) {
      this.project.groupList[key].componentList[key1].component.isHovered = true;
      this.$forceUpdate();
    },

    // 鼠标移出
    bindMouseout(key, key1) {
      this.project.groupList[key].componentList[key1].component.isHovered = false;
      this.$forceUpdate();
    },

    // 拖拽开始
    handleDragStart(e) {
      if (e.target.dataset.disabled) return;
      this.control.dragWidget = this.$getNewComponent(e.target.dataset.component);
      this.control.dragstatus = true;
    },

    // 点击组件
    openClose(item, index) {
      if (item.expanded) {
        this.project.groupList[index].expanded = false
      } else {
        this.project.groupList[index].expanded = true
      }
      this.$forceUpdate();
    },

    // 拖拽结束
    dragEnd(e) {
      if (e.target.dataset.disabled) {
        this.control.dragstatus = false;
        return;
      };

      this.control.h5Iframe.contentWindow.postMessage({ even: "drop", params: this.control.dragWidget }, "*");
      this.control.dragstatus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variable";

.tree {
  list-style-type: none;
  padding-left: 20px;
}

.toggle {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.item-disabled {
  cursor: not-allowed;
  opacity: 0.5
}

.wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 380px;
  overflow: hidden;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 72px;
  margin-bottom: 8px;
  padding: 10px 0 0;
  font-size: 12px;
  color: #666;

  // border: 1px solid #eee;

  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;

  i {
    font-size: 29px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  &:hover {
    color: #fff;
    background: $color-theme;
  }

}
</style>
